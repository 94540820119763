<template>

		<v-app-bar prominent>
			<RouterLink to="/">
				<img id="logo-img" src="@/assets/logo-def.jpg" alt="logo"/>
			</RouterLink>
			<v-spacer></v-spacer>

			<div class="select">
				<span class="select__flag" aria-hidden="true"></span>
 <!--
				<v-select
				label=""
				item-title="name"
				item-text="name"
				item-value="image"
				v-model="select"
				:items="items" v-on:change="changeLocale"
				single-line
				return-object
				persistent-hint
				></v-select>


			-->
			
				<select class="select__input" @change="changeLocale($event)" name="country-selector" id="country-selector">
					<option value="en" >English</option>
					<option value="it">Italiano</option>
					<option value="es">Español</option>
				</select>
			

			</div>


			<v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
		</v-app-bar>
		<v-navigation-drawer v-model="drawer" location="right">
				<v-list >
					<v-container class="baggy-menu">
					<v-row>
						<v-col>
							<strong><a @click="goto('our-values')">{{ $t('message.menu.ourValues') }}</a></strong>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<strong><a @click="goto('how-it-works')">{{ $t('message.menu.howItWorks') }}</a></strong>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<strong><a @click="goto('become-partner')">{{ $t('message.menu.becomePartner') }}</a></strong>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<strong><a @click="goto('faq')">{{ $t('message.menu.faq') }}</a></strong>
						</v-col>
					</v-row>
					
					</v-container>
				</v-list>
			</v-navigation-drawer>
				
</template>



<style>

.select__input {
  /* Reset default dropdown style */
  appearance: none;
  border: 0;
  margin: 0;
  font-size: 1rem;
  width: 100%;
  height: 100%;
  /* Custom dropdown styling */
  padding: 0.5rem 1rem;
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.1), 0 0 0 1px hsla(230, 13%, 9%, 0.02), 0 0.3px 0.4px hsla(230, 13%, 9%, 0.025), 0 1px 3px -1px hsla(230, 13%, 9%, 0.2), 0 3.5px 6px hsla(230, 13%, 9%, 0.12);
  border-radius: 0.375em;
  transition: box-shadow 0.2s;
  user-select: none;
}

.select__input:focus  {
  outline: none;
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.1), 0 0 0 1px hsla(230, 13%, 9%, 0.02), 0 0.3px 0.4px hsla(230, 13%, 9%, 0.025), 0 1px 3px -1px hsla(230, 13%, 9%, 0.2), 0 3.5px 6px hsla(230, 13%, 9%, 0.12), 0 0 0 2px hsl(0, 0%, 100%), 0 0 0 4px hsl(230, 7%, 23%);
}

:root {
  --select-icon-size: 16px; /* Icon size */
  --select-icon-margin: 1rem; /* Icon left/right positioning */
  --select-text-icon-gap: 0.5rem; /* Space between text and icon */
}

.select {
  position: relative;
}

.select__arrow,
.select__flag {
  display: inline-block;
  position: absolute;
  /* Center both icons vertically */
  top: 50%;
  transform: translateY(-50%);
  width: var(--select-icon-size);
  height: var(--select-icon-size);
  pointer-events: none;
}

.select__arrow {
  color: inherit;
  right: var(--select-icon-margin);
}

.select__flag {
  left: var(--select-icon-margin);
}

.select__input {
  padding: 0.5rem calc(var(--select-icon-size) + var(--select-icon-margin) + var(--select-text-icon-gap));
}

.select__flag {
  background-position: center;
  background-repeat: no-repeat;
  background-size: var(--select-icon-size);
  /* Default world icon as SVG dataURI */
  background-image: url("@/assets/icons/uk-flag.svg");
}

.select:has(option[value="it"]) {}

.select:has(option[value="it"]:checked) {}

.select:has(option[value="it"]:checked) .select__flag {
  /* SVG Italian flag via dataURI  */	
  background-image: url("@/assets/icons/italy-flag.svg");
}

.select:has(option[value="es"]) {}

.select:has(option[value="es"]:checked) {}

.select:has(option[value="es"]:checked) .select__flag {
  /* SVG Italian flag via dataURI  */	
  background-image: url("@/assets/icons/es-flag.svg");
}




a:hover {
	cursor: pointer;
}

.baggy-menu {
	color: #1c4c34;
}

.languages {
	margin-top:10px;

}

.flag {
	width: 30px;
}

.flag:hover {
	cursor: pointer;
}

</style>


<script>
/*
	const changeLocale = (locale) => {
		var value = locale.value;  
		console.log(value);
    };
*/


 export default {
	data: () => ({
		drawer: false,
		group: null
		/*
		select: { name: 'en', image: '@/assets/icons/uk-flag.svg' },
		items: [
			{ name: 'en', image: '@/assets/icons/uk-flag.svg'},
			{ name: 'it', image: '@/assets/icons/italy-flag.svg'}]
		*/
		}
	),

    watch: {
      group () {
        this.drawer = false
      },
    },
   methods: {
    goto(refName) {
        var element = document.getElementById(refName).offsetTop-80;
        window.scrollTo(0, element);
        this.drawer=false;

    },
    changeLocale(locale) {
		var value = locale.target.value;  
		this.$i18n.locale = value;
	}
	
  }
}
</script>


