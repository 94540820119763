<template>
  <div class="faq-section">
    <v-row class="faq-section-title">
      <h1 class="faq-title">FAQ</h1>
    </v-row>
    <v-row>
      <v-expansion-panels class="faq-section-panels">
        <v-expansion-panel class="faq-section-panel">
          <v-expansion-panel-title>
            <p class="faq-accordion-title">
              {{ $t("message.FAQ.faq1.title") }}
            </p>
          </v-expansion-panel-title>
          <v-expansion-panel-text class="description">
            <p>{{ $t("message.FAQ.faq1.paragraph1") }}</p>
            <p>{{ $t("message.FAQ.faq1.paragraph2") }}</p>
            <p>{{ $t("message.FAQ.faq1.paragraph3") }}</p>
            <p>{{ $t("message.FAQ.faq1.paragraph4") }}</p>
            <p>{{ $t("message.FAQ.faq1.paragraph5") }}</p>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels class="faq-section-panels">
        <v-expansion-panel class="faq-section-panel">
          <v-expansion-panel-title>
            <p class="faq-accordion-title">
              {{ $t("message.FAQ.faq2.title") }}
            </p>
          </v-expansion-panel-title>
          <v-expansion-panel-text class="description">
            {{ $t("message.FAQ.faq2.paragraph1") }}
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels class="faq-section-panels">
        <v-expansion-panel class="faq-section-panel">
          <v-expansion-panel-title>
            <p class="faq-accordion-title">
              {{ $t("message.FAQ.faq3.title") }}
            </p>
          </v-expansion-panel-title>
          <v-expansion-panel-text class="description">
            {{ $t("message.FAQ.faq3.paragraph1") }}
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels class="faq-section-panels">
        <v-expansion-panel class="faq-section-panel">
          <v-expansion-panel-title>
            <p class="faq-accordion-title">
              {{ $t("message.FAQ.faq4.title") }}
            </p>
          </v-expansion-panel-title>
          <v-expansion-panel-text class="description">
            {{ $t("message.FAQ.faq4.paragraph1") }}
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels class="faq-section-panels">
        <v-expansion-panel class="faq-section-panel">
          <v-expansion-panel-title>
            <p class="faq-accordion-title">
              {{ $t("message.FAQ.faq5.title") }}
            </p>
          </v-expansion-panel-title>
          <v-expansion-panel-text class="description">
            {{ $t("message.FAQ.faq5.paragraph1") }}
          </v-expansion-panel-text>
          <v-expansion-panel-text class="description">
            {{ $t("message.FAQ.faq5.paragraph2") }}
          </v-expansion-panel-text>
          <v-expansion-panel-text class="description">
            {{ $t("message.FAQ.faq5.paragraph3") }}
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels class="faq-section-panels">
        <v-expansion-panel class="faq-section-panel">
          <v-expansion-panel-title>
            <p class="faq-accordion-title">
              {{ $t("message.FAQ.faq6.title") }}
            </p>
          </v-expansion-panel-title>
          <v-expansion-panel-text class="description">
            {{ $t("message.FAQ.faq6.paragraph1") }}
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels class="faq-section-panels">
        <v-expansion-panel class="faq-section-panel">
          <v-expansion-panel-title>
            <p class="faq-accordion-title">
              {{ $t("message.FAQ.faq7.title") }}
            </p>
          </v-expansion-panel-title>
          <v-expansion-panel-text class="description">
            {{ $t("message.FAQ.faq7.paragraph1") }}
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels class="faq-section-panels">
        <v-expansion-panel class="faq-section-panel">
          <v-expansion-panel-title>
            <p class="faq-accordion-title">
              {{ $t("message.FAQ.faq8.title") }}
            </p>
          </v-expansion-panel-title>
          <v-expansion-panel-text class="description">
            {{ $t("message.FAQ.faq8.paragraph1") }}
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels class="faq-section-panels">
        <v-expansion-panel class="faq-section-panel">
          <v-expansion-panel-title>
            <p class="faq-accordion-title">
              {{ $t("message.FAQ.faq9.title") }}
            </p>
          </v-expansion-panel-title>
          <v-expansion-panel-text class="description">
            {{ $t("message.FAQ.faq9.paragraph1") }}
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

    </v-row>
  </div>
</template>

<style>
.faq-section {
  .faq-section-title {
    flex-direction: column;
    text-align: center;
  }
  .faq-section-panels {
    @media (min-width: 481px) {
      margin: 15px 100px 15px 100px;
    }
    @media (max-width: 481px) {
      margin: 10px 25px 10px 25px;
      margin-right: 25px !important;
    }
  }

  .faq-section-panel {
    background-color: white;
    border: 0.6px solid #f3f4fe;
    box-shadow: 0px 11.93px 56.67px 0px #c9cbcc4d;
    gap: 0px;
    opacity: 1;
  }

  .faq-accordion-title {
    font-size: 22px;
    color: #1c4c34;
    width: 100%;
    font-family: Wotfard-SemiBold;
    padding: 5px;
  }

  .description {
    color: #637381;
    font-family: wotfard-regular-webfont;
  }

  @media screen and (min-width: 601px) {
    .faq-title {
      color: #9fcb78;
      font-family: Wotfard-Bold;
      font-size: 40px;

      margin: 40px 0 40px 0;
    }

    .description p {
      font-size: 16px;
      padding: 3px;
    }
  }
  @media (max-width: 600px) {
    .faq-title {
      color: #9fcb78;
      font-size: 24px;
      font-family: Wotfard-Bold;
      margin: 25px 0 25px 0;
    }

    .faq-accordion-title {
      font-size: 4.5vw;
    }

    .description p {
      font-size: 14px;
      padding: 3px;
    }
  }
}
</style>

<script>
export default {
  name: "FAQSection",
};
</script>
