<template>
  <div class="information-box">
    <div class="text">
      <h3>{{ $t("message.bookingSectionV0.header0") }}</h3>
      <p> {{ $t("message.bookingSectionV0.header1") }}</p>
    </div>
  </div>

  <div class="city-section">
    <div class="city-box" @click="bookMilano('milano')">
      <img src="@/assets/milano.jpg" alt="" class="img" />
      <div class="text-img">
        <div class="city-place">
          <div >Milano - &nbsp;</div>
          <div style="font-family:wotfard-regular-webfont ;">Italia</div>
        </div>
        <span class="hidden-place" style="color: #9fcb78; font-family: Wotfard-Bold" >{{
          $t("message.booking.find")
        }}</span>
      </div>
    </div>
    <div class="city-box" style="cursor: unset">
      <img
        src="@/assets/lecce.jpg"
        alt=""
        class="img"
        style="filter: brightness(0.4)"
      />
      <div class="text-img">
        <div class="city-place">
          <div>Lecce -&nbsp;</div>
          <div style="font-family:wotfard-regular-webfont ;">Italia</div>
        </div>
        <span class="hidden-place" style="color: #9fcb78; font-family: Wotfard-Bold">{{
          $t("message.booking.comingsoon")
        }}</span>
      </div>
    </div>
     
    <div class="city-box" >
      <div class="other-destination" v-if="isMobile" @click="goto('mail')">
          <p class="ask">{{
            $t("message.booking.otherDestination")
          }}</p>
          <span class="contact-us">
          {{
            $t("message.booking.contactUs")
          }}</span>
      </div>
      <div class="other-destination" v-if="!isMobile" @click="goto('footer')">
          <p class="ask">{{
            $t("message.booking.otherDestination")
          }}</p>
          <span class="contact-us">
          {{
            $t("message.booking.contactUs")
          }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref } from "vue";

export default {
  name: "BookingSectionV0",
  mounted() {
        this.checkWindowWidth();
        window.addEventListener("resize", this.checkWindowWidth); //resize è l'evento che accade quando la finestra viene ridimensionata dall'utente
    },
  setup(props, { emit }) {
    const store = useStore(); // Accedi allo store

    const bookMilano = (city) => {
      emit("zeroStep", city);
      store.dispatch("updateCityName", city); // Modifica lo stato city nello store
    };
    const windowWidth = ref(null);
      const isMobile = ref(false);

      const checkWindowWidth = () => {
        windowWidth.value = window.innerWidth;
        if (windowWidth.value < 800) {
          isMobile.value = true;
        } else {
          isMobile.value = false;
        }
      };
    return {
      bookMilano,
      checkWindowWidth,
      windowWidth,
      isMobile
    };
  },
  watch: {
      windowWidth() {
        this.checkWindowWidth(); // Ogni volta che windowWidth cambia, controlla
      },
    },
    methods: {
      goto(refName) {
        if(refName==='footer'){
          var element = document.getElementById(refName).offsetTop - 80;
          window.scrollTo(0, element);
        }else {
          console.log("mail");
          window.location.href =
          "mailto:infocontact@baggysitter.com?subject=Contact us";
        }
      },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  width: 100%;
  text-align: start;
  margin: 0;
  padding: 0;
  color: #1c4c34;
  font-family: Wotfard-SemiBold;
  font-size: 24px;
}

p {
  width: 100%;
  text-align: center;
  font-family: wotfard-regular-webfont;
  color: white;
}

span {
  width: 100%;
  color: white;
}

.information-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.text {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;

}

.text p{
  color:#1c4c34;
  width: 100%;
  text-align: start;
  font-family:wotfard-regular-webfont ;
  padding: 0;
  font-size: 16px;
}

.icon-search {
  width: 25px;
}

.icon-search img {
  width: 100%;
}

.city-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  
}
.city-box {
  width: 100%;
  height: 100px;
  overflow: hidden; /* Impedisce all'immagine di uscire dai bordi */
  border-radius: 6px;
  border: 3px solid #9fcb78;
  position: relative;

  background-color: inherit;

  cursor: pointer;

  transition: transform 0.5s ease-in-out;
}


@media (min-width: 601px) {
  .ask {
    font-family: wotfard-regular-webfont;   
    font-size: 14px;
  }

  .contact-us {
    color: #9fcb78; 
    font-family: wotfard-regular-webfont;
    font-size:14px;
  }
}

@media  (max-width: 600px) {
  .ask {
    font-family: Wotfard-Bold;   
    font-size:13px;
  }
  .contact-us {
    color: #9fcb78; 
    font-family: wotfard-regular-webfont;
    font-size: 10px;
  }
}

.img {
  width: 100%;
  transform: translateY(-25%);
  position: relative;
  z-index: 0;

  filter: brightness(
    0.8
  ); /* Riduce la luminosità al 50% per scurire l'immagine */
}

.text-img {
  position: absolute;
  top: 24%;
  left: 15%;
  z-index: 1;

  width: 70%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.city-place{
  display: flex;
  flex-direction: row;
  width: 100%;
  text-align: center;
  align-items: start;
  justify-content: center;
  color: white;
  transform: translateY(10px);
  transition: transform 0.5s ease-in-out;

  font-size: 24px;
}

.hidden-place{
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.city-box:hover .hidden-place {
  top: 80%; /* Sposta leggermente verso l'alto quando appare */
  opacity: 1;
}

.city-box:hover .city-place {
  transform: translateY(2px); /* Sposta il testo leggermente verso l'alto */
}

.other-destination {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Nero al 60% di opacità */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1vw;
}

.other-destination p{
  font-family: wotfard-regular-webfont;
  font-size: 16px;
  padding: 0;
}

@media (max-width: 768px) {
  .city-box{
    height: 80px;
  }

  .city-place {
    transform: translateY(4px);
  }
}
</style>
