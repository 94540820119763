<template>
  <v-container>
    <section
      class="v-container v-container--fluid v-locale--is-ltr pa-4 pa-sm-6 pa-md-8"
    >
      <v-row class="become-partner-section-title">
        <h1>
          {{ $t("message.becomePartner.title1") }}
          <span style="color: #9fcb78">
            {{ $t("message.becomePartner.title2") }}
          </span>
        </h1>
      </v-row>

      <v-row>
        <v-col-1>
          <img src="@/assets/star.png" class="become-img" alt="become-img" />
        </v-col-1>
        <v-col>
          <strong>{{ $t("message.becomePartner.subtitle1") }}</strong>
        </v-col>
      </v-row>

      &nbsp;
      <v-row>
        <v-col-1>
          <img src="@/assets/cup.png" class="become-img" alt="become-img" />
        </v-col-1>
        <v-col>
          <strong>{{ $t("message.becomePartner.subtitle2") }}</strong>
        </v-col>
      </v-row>

      &nbsp;
      <v-row>
        <v-col-1>
          <img src="@/assets/smile.png" class="become-img" alt="become-img" />
        </v-col-1>
        <v-col>
          <strong>{{ $t("message.becomePartner.subtitle3") }}</strong>
        </v-col>
      </v-row>

      &nbsp;
      <v-row>
        <v-col-1>
          <img src="@/assets/done.png" class="become-img" alt="become-img" />
        </v-col-1>
        <v-col>
          <strong>{{ $t("message.becomePartner.subtitle4") }}</strong>
        </v-col>
      </v-row>
      &nbsp;

      <v-row>
        <v-col v-if="isMobile">
        <v-btn id="Message" class="become-button" @click="goto()" color="#1c4c34"> 
        {{$t('message.becomePartner.joinus')}}
        </v-btn>
        </v-col>

        <v-col v-if="!isMobile">
          <v-dialog max-width="500">
            <template v-slot:activator="{ props: activatorProps }">
              <v-btn
                class="become-button"
                style="background-color: #1c4c34; color: white"
                v-bind="activatorProps"
                variant="flat"
                >{{ $t("message.becomePartner.joinus") }}</v-btn
              >
            </template>

            <template v-slot:default="{ isActive }">
              <v-card title="">
                <v-card-text>
                  {{ $t("message.becomePartner.joinusDescription") }}
                  <RouterLink to="/"> infocontact@baggysitter.com</RouterLink>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn @click="isActive.value = false">
                    {{ $t("message.becomePartner.close") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-col>
      </v-row>

      <v-row style="text-align: center; margin-top: 15px">
        <v-col>
          <a href="//dominamilano.com" target="_blank">
            <img src="@/assets/partner/domina.png" style="width: 200px; height: 200px;" alt="domina Img"/>
          </a>
        </v-col>
        <v-col>
          <a href="//milanbag.it" target="_blank">
            <img src="@/assets/partner/milanbag.png" style="width: 200px; height: 200px;" alt="milanBag Img"/>
          </a>
        </v-col>
        <v-col>
          <a href="//curioo.app" target="_blank">
            <img src="@/assets/partner/curiooapp.png" style="height: 200px;" alt="curiooapp Img"/>
          </a>
        </v-col>
        <v-col>
          <a href="//januamajor.it" target="_blank">
            <img src="@/assets/partner/januamajor.svg" style="width: 200px; height: 200px;" alt="januamajor Img"/>
          </a>
        </v-col>
      </v-row>
    </section>
  </v-container>
</template>


<script>
  import { RouterLink } from "vue-router";
  import { ref } from "vue";

  export default {
    name: "BecomePartnerView",
    components: {
      RouterLink,
    },
    mounted() {
        this.checkWindowWidth();
        window.addEventListener("resize", this.checkWindowWidth); //resize è l'evento che accade quando la finestra viene ridimensionata dall'utente
    },
    setup() {
      const windowWidth = ref(null);
      const isMobile = ref(false);

      const checkWindowWidth = () => {
        windowWidth.value = window.innerWidth;
        if (windowWidth.value < 800) {
          isMobile.value = true;
        } else {
          isMobile.value = false;
        }
      };
      return {
        checkWindowWidth,
        windowWidth,
        isMobile,
      };
    },
    watch: {
      windowWidth() {
        this.checkWindowWidth(); // Ogni volta che windowWidth cambia, controlla
      },
    },
    methods: {
      goToHome() {
        this.$router.push("/");
      },
      goto() {
        window.location.href =
          "mailto:infocontact@baggysitter.com?subject=Join us";
      }
    },
  };
</script>

<style>
.become-partner-section-title {
  flex-direction: column;
  text-align: center;
}

.become-img {
  width: 20px;
  margin-top: 15px;
}

.become-button {
  @media screen and (min-width: 480px) {
    width: 240px;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    font-family: wotfard-Bold;
  }
}

@media (min-width: 601px) {
  .become-partner-section-title h1 {
    font-family: wotfard-Bold;
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 40px;
  }
}

@media (max-width: 600px) {
  .become-partner-section-title h1 {
    font-size: 24px;
    font-family: wotfard-Bold;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
</style>
