<template>
  <!-- :class="{'gm-apAutocomplete': !isAddressWrong, 'gm-autocomplete-wrong': isAddressWrong}" -->
  <GMapAutocomplete
    class="gm-autocomplete"
    :placeholder="placeholder"
    :options="autocompleteOptions"
    @input="onInputChange"
    @focus="onFocus"
    @blur="onBlur"
    @place_changed="onPlaceChanged"
  >
  </GMapAutocomplete>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";

export default {
  name: "autocompleteMilano",
  props: {
    placeholder: {
      type: String,
    },
    options: {
      type: Object,
      required: true,
    },
  },

  components: {},

  beforeCreate() {
    // Codice eseguito subito prima che la componente venga creata
    this.localPlaceholder = this.placeholder;
  },

  setup(props, { emit }) {
    let googleMapInstance;
    const polygonCoords = [
      { lat: 45.603903709664394, lng: 8.708910472275434 }, //milano malpensa
      { lat: 45.646284771909194, lng: 8.704069594448216 },
      { lat: 45.65505791009713, lng: 8.73472848735393 },
      { lat: 45.6189077868712, lng: 8.860693490802783 },
      { lat: 45.53875682387021, lng: 9.049067881797274 }, // Busto Arsizio
      { lat: 45.58054297086245, lng: 9.292984083805848 }, // Monza
      { lat: 45.38701449374106, lng: 9.306716993283842 }, // San Giuliano Milanese
      { lat: 45.376541382869, lng: 9.13760650968696 }, // Rozzano
      { lat: 45.49814033850681, lng: 9.025428958227522 }, // Cornaredo
    ];
    const isInPolygon = false;
    const { t } = useI18n();

    // Riferimento all'elemento autocomplete
    const selectedAddress = ref("");
    const input = ref("");
    const localPlaceholder = ref(props.placeholder); //placeholder di default quando il componente viene cliccato per la prima volta
    const isAddressWrong = ref(false);

    const onInputChange = (event) => {
      if (event.target.value == "") {
        emit("address-selected", t("message.bookingSectionV1.selectPlace")); //cambio il placeholder
        emit("wrong-address", true);
      }
      if (event.target.value) {
        // se l'input esiste
        selectedAddress.value = "";
        emit("address-selected", "wrong");
        input.value = event.data;

        isAddressWrong.value = true;
        emit("wrong-address", true);
      }
    };

    //funzione che appartiene alle api di google place indica il valore scelto dalla tendina
    /* const onPlaceChanged = (place) => {
      isAddressWrong.value = false;
      emit("wrong-address", false);

      if (place.formatted_address.includes("Cin")) {
        const name = place.name || ""; // Nome del luogo
        const regex = /Cin:\s?[\w\d]+,?\s?/i; // Espressione regolare per il CIN

        // Sostituzione del CIN con il nome del luogo
        selectedAddress.value = place.formatted_address
          .replace(regex, `${name}, `)
          .trim();

        emit("address-selected", selectedAddress.value);

      }else if(!place.formatted_address.includes(place.name)){
        console.log("è un hotel")
        selectedAddress.value =  `${place.name} ${place.formatted_address}`.trim()
        emit("address-selected", selectedAddress.value);
      }
      else {
        console.log("è unsa via");
        selectedAddress.value = place.formatted_address; // formatto il valore scelto come via (stringa)
        emit("address-selected=", selectedAddress.value);
      }
    }; */

    //funzione che appartiene alle api di google place indica il valore scelto dalla tendina
    const onPlaceChanged = (place) => {
      isAddressWrong.value = false;
      emit("wrong-address", false);
      selectedAddress.value = place.formatted_address; // formatto il valore scelto come via (stringa)
      emit("address-selected", selectedAddress.value);

      //ELIMINAZIONE DEL CIN PER EMAIL
      if (place.formatted_address.includes("Cin")) {
        const name = place.name || ""; // Nome del luogo
        const regex = /Cin:\s?[\w\d]+,?\s?/i; // Espressione regolare per il CIN

        // Sostituzione del CIN con il nome del luogo
        selectedAddress.value = place.formatted_address
          .replace(regex, `${name}, `)
          .trim();

        emit("address-selected", selectedAddress.value);

      }else if(!place.formatted_address.includes(place.name)){
        selectedAddress.value =  `${place.name} ${place.formatted_address}`.trim()
        emit("address-selected", selectedAddress.value);
      }
      else {
        selectedAddress.value = place.formatted_address; // formatto il valore scelto come via (stringa)
        emit("address-selected=", selectedAddress.value);
      }

      googleMapInstance = window.google;

      var latitude = place.geometry.location.lat();
      var longitude = place.geometry.location.lng();

      const polygon = new googleMapInstance.maps.Polygon({
        paths: polygonCoords,
      });

      //se la via è al difuori del poligono, scirve false
      setTimeout(function () {
        var rsul = window.google.maps.geometry.poly.containsLocation(
          new window.google.maps.LatLng(latitude, longitude),
          polygon
        );
        this.isInPolygon = rsul;
        emit("is-over-polygon", this.isInPolygon);

      }, 50);
    };


    const onFocus = (event) => {
      //quando pigio sulla componente
      localPlaceholder.value = ""; // il placeholder divents "", nulla c'è piu scritto in questo preciso momento
      event.target.placeholder = localPlaceholder.value; // Forza il re-render del componente se non aggiorna automaticamente

      if (isAddressWrong.value == true) {
        //se la via è gia sbagliata
        emit("wrong-address", true);
      }
    };

    // Opzioni per limitare la ricerca a Palermo
    const autocompleteOptions = ref({
      componentRestrictions: { country: "IT" }, // Limita all'Italia
      types: ["geocode", "establishment"], // Limita ai risultati di tipo indirizzo
      bounds: {
        north: 45.71474686985534, // Coordinata nord: Castelletto Sopra Ticino
        south: 45.316482394275106, // Coordinata sud: Vigevano
        east: 9.3239755, // Coordinata est: Melegnano
        west: 8.637861372681899, // Coordinata ovest: Castelletto Sopra Ticino
      }, // Limita all'area geografica di Milano
      strictBounds: true, // Assicura che la ricerca rimanga entro i limiti
    });

    const onBlur = (event) => {
      if (
        input.value == null ||
        input.value == undefined ||
        input.value == ""
      ) {
        localPlaceholder.value = props.placeholder; //placeholde di defoult
        event.target.placeholder = localPlaceholder.value; //forzo il cambio del placeholder
      } else {
        localPlaceholder.value = props.placeholder;
        event.target.placeholder = localPlaceholder.value;
      }
      emit("address-selected", selectedAddress.value);
    };

    return {
      autocompleteOptions,
      selectedAddress,
      onInputChange,
      onFocus,
      onBlur,
      onPlaceChanged,
      polygonCoords,
      googleMapInstance,
      isOverPolygon: isInPolygon,
    };
  },
};
</script>

<style>
.gm-autocomplete {
  text-align: start;
  font-size: 0.9rem;
  font-family: Wotfard-SemiBold;
  width: 100%;
  cursor: pointer;
  color: #1c4c34;
  text-overflow: ellipsis; /* Aggiunge "..." alla fine del testo se è troppo lungo */
  border-bottom: 1px solid #1c4c34;
  /*  border-radius: 5px; */

  margin-bottom: 4px;
  padding: 5px 0 5px 5px;
}

::placeholder {
  color: #1c4c34;
}

@media (max-width: 1000px) {
  .gm-autocomplete {
    font-size: 1.3rem;
  }
}

@media (max-width: 480px) {
  .gm-autocomplete {
    font-size: 16px;
  }
}
</style>
